<template>
  <div
    class="login-page"
    data-testid="login-page"
  >
    <div class="header">
      <AppLogo />
      <div class="title">
        <h5>
          {{ brandName }}
        </h5>
        <h5 class="semi-bold">
          {{ modelName }}
        </h5>
      </div>
    </div>
    <div
      class="content"
    >
      <div class="logo-img" />
      <div
        v-if="!shouldShowResetPassword && !shouldShowTrendMicroWizard"
        class="panel login-panel"
      >
        <div class="login-content">
          <div class="title">
            <h1 class="semi-bold">
              {{ $t('Log in') }}
            </h1>
          </div>
          <div
            class="blocked-error"
            v-if="isLoginBlocked"
          >
            <div class="error-title bold">
              <svg-icon
                iconClass="general_hint"
                size="16px"
              />
              {{ $t('Log in is blocked') }}
            </div>
            {{ $t('You’ve tried to log in too many times with an incorrect account or password.') }}
            {{ $t('Please try again in 30 minutes.') }}
          </div>
          <div class="panel-field">
            <h5 class="bold">
              {{ $t('User name') }}
            </h5>
            <AppInputFields
              data-testid="username-input"
              id="username"
              v-model="userName"
              @keyup.native.enter="requestLogin"
            />
          </div>
          <div class="panel-field password">
            <h5 class="bold">
              {{ $t('Password') }}
            </h5>
            <AppInputFields
              data-testid="password-input"
              :type="isPasswordVisible ? 'text' : 'password'"
              id="password"
              v-model="password"
              :hasError="isLoginError"
              :errorMessage="loginErrorMessage"
              @keyup.native.enter="requestLogin"
            />
            <SvgIcon
              :iconClass="`general_${ isPasswordVisible ? 'hide' : 'show' }`"
              :title="isPasswordVisible ? $t('Hide password') : $t('Show password')"
              size="20px"
              outerSize="32px"
              @click="togglePasswordVisible"
            />
          </div>
          <div
            class="panel-field"
          >
            <h5 class="bold">
              {{ $t('Language') }}
            </h5>
            <AppDropdown
              data-testid="language-dropdown"
              :options="languageOptions"
              v-model="language"
            />
          </div>
          <div class="panel-field remember-me">
            <input
              data-testid="remember-me"
              type="checkbox"
              trueValue="1"
              falseValue="0"
              v-model="shouldRememberUser"
            >
            <h6>{{ $t('Remember me') }}</h6>
          </div>
          <div class="panel-field hint-message">
            <template v-if="!isIE">
              <div class="hint-title">
                <SvgIcon
                  iconClass="general_hint"
                  size="16px"
                />
                <span class="bold">{{ $t('For best performance, we suggest :') }}</span>
              </div>
              <span>1. {{ $t('PC minimum spec requirement: CPU i5 or above with 8GB RAM.') }}</span>
              <span>2. {{ $t('Camera H.264 video stream with resolution 640 x 360 or under.') }}</span>
            </template>
            <template v-else>
              <div class="hint-title">
                <SvgIcon
                  iconClass="general_hint"
                  size="12px"
                />
                <span class="bold">{{ $t('We’ve uploaded the new NVR web UI.') }}</span>
              </div>
              <span>{{ $t('Please use Chrome browser for experiencing VIVOTEK new NVR web UI.') }}</span>
            </template>
          </div>
          <div class="button-list">
            <button
              data-testid="login-button"
              class="apply-button login-button"
              :class="{ disabled: isLoginBlocked, loading: isProcessing }"
              @click="requestLogin"
            >
              <span>
                {{ $t('Log in') }}
              </span>
              <SvgIcon
                class="icon spinning"
                iconClass="status_loading_s"
                size="20px"
                v-show="isProcessing"
              />
            </button>
          </div>
        </div>
        <!--<div class="link">
            <a>
              {{ $t('Help') }}
            </a>
            <a>
              {{ $t('Privacy') }}
            </a>
            <a>
              {{ $t('Terms') }}
            </a>
          </div>-->
      </div>
      <ResetPasswordPanel
        v-if="shouldShowResetPassword"
        class="panel"
        @onResetPassword="doSetupTmis"
      />
      <div
        v-if="shouldShowTrendMicroWizard"
        class="panel"
      >
        <div class="tmis-content">
          <div class="title">
            <h1 class="semi-bold">
              {{ $t('Trend Micro IoT Security') }}
            </h1>
            <h6>
              {{ $t('Accept end user license agreement to enable service.') }}
            </h6>
          </div>
          <TrendMicroAgreement
            class="panel-field"
          />
          <div class="button-list">
            <button
              class="apply-button"
              @click="finishTrendMicroWizard({ accept: true })"
            >
              {{ $t('Accept') }}
            </button>
            <button
              class="cancel-button"
              @click="finishTrendMicroWizard({ accept: false })"
            >
              {{ $t('No') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import i18n from '@/i18n/index';
import SystemApi from '@/services/SystemApi';
import BrowserDetector from '@/utility/browserDetector';
import AppLogo from '@/components/AppLogo.vue';
import AppInputFields from '@/components/ui/AppInputFields.vue';
import AppDropdown from '@/components/ui/AppDropdown.vue';
import ResetPasswordPanel from '@/pages/login/ResetPasswordPanel.vue';
import TrendMicroAgreement from '@/pages/login/TrendMicroAgreement.vue';

export default {
  name: 'Login',
  data() {
    return {
      userName: '',
      password: '',
      language: '',
      loginErrorMessage: '',
      shouldRememberUser: false,
      shouldShowResetPassword: false,
      shouldShowTrendMicroWizard: false,
      isProcessing: false,
      isLoginError: false,
      isLoginBlocked: false,
      isPasswordVisible: false,
    };
  },
  components: {
    AppLogo,
    AppInputFields,
    AppDropdown,
    ResetPasswordPanel,
    TrendMicroAgreement
  },
  computed: {
    ...mapState(['user', 'system']),
    ...mapGetters('user', ['isPasswordVerified', 'isTmisVerified']),
    ...mapGetters('system', ['hasLiveviewFeature']),
    languageOptions() {
      return Object.keys(i18n.languages).map((language) => ({ text: i18n.languages[language], value: language }));
    },
    brandName() {
      return this.system.configure.CONFIG_BRAND?.toUpperCase() || '';
    },
    modelName() {
      return this.system.configure.CONFIG_EXTENDED_MODEL_NAME || '';
    },
    isIE() {
      return BrowserDetector.browser === 'Internet Explorer';
    }
  },
  watch: {
    language(value) {
      this.switchLanguage(value);
    }
  },
  methods: {
    ...mapActions('user', ['checkLoginVerified', 'logout']),
    ...mapActions('system', ['fetchSystemConfigure']),
    requestLogin() {
      const { userName, password } = this;
      this.isLoginError = false;
      this.loginErrorMessage = '';
      this.isProcessing = true;
      return this.checkLoginVerified({ userName, password })
        .then(this.onLoginSuccess)
        .catch((err) => {
          this.onLoginError(err);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    onLoginSuccess() {
      if (!this.verifyUserPermission()) {
        this.logout();
        return;
      }
      this.updateUserName();
      if (!this.isPasswordVerified) {
        this.shouldShowResetPassword = true;
        return;
      }
      this.doSetupTmis();
    },
    verifyUserPermission() {
      if (!this.hasLiveviewFeature && this.user.group !== 'administrator') {
        this.onLoginError('Error: not authorized to perform operation');
        return false;
      }
      return true;
    },
    doSetupTmis() {
      this.shouldShowResetPassword = false;
      if (!this.isTmisVerified) {
        this.shouldShowTrendMicroWizard = true;
        return;
      }
      this.goToEntryPage();
    },
    updateUserName() {
      if (this.shouldRememberUser) {
        document.cookie = `savedUserName=${this.userName}; max-age=${60 * 60 * 24 * 2};`;
      } else {
        document.cookie = 'savedUserName=;';
      }
    },
    onLoginError(err) {
      switch (err.toString()) {
        case 'Error: Request failed with status code 401':
          this.isLoginError = true;
          this.loginErrorMessage = this.$t('The user name or password is incorrect.');
          break;
        case 'Error: not authorized to perform operation':
          this.isLoginError = true;
          this.loginErrorMessage = this.$t('Administrator only');
          break;
        default:
          break;
      }
    },
    goToEntryPage() {
      // For phase 2, we still provide both new and old web.
      // So our old web requires subdir (classic).
      let entryUrl;
      if (this.hasLiveviewFeature) {
        entryUrl = this.isIE ? '/classic/index.html' : '/liveview.html';
      } else {
        entryUrl = '/settings.html';
      }

      const url = `${window.location.origin}${entryUrl}`;
      location.href = url;
    },
    finishTrendMicroWizard({ accept }) {
      SystemApi.finishTrendMicroWizard(accept)
        .then(this.goToEntryPage);
    },
    togglePasswordVisible() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    switchLanguage(lang) {
      i18n.switchLanguage(lang);
    },
    readUserName() {
      const savedUserName = document.cookie.split(';').find((item) => item.trim().startsWith('savedUserName='))?.split('=')[1];
      this.userName = savedUserName || '';
      this.shouldRememberUser = this.userName !== '';
    }
  },
  created() {
    this.readUserName();
    this.checkLoginVerified()
      .then(this.onLoginSuccess)
      .catch((error) => {
        console.error(error);
      });
  },
  mounted() {
    this.language = localStorage.getItem('language') || 'English';

    this.fetchSystemConfigure().then(() => {
      document.title = `${this.brandName} ${this.modelName}`;
    });
  }
};
</script>

<style lang="less" scoped>
.login-page {
  background-image: url('../../assets/bg_nvr_@2x.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: rem(48);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > .title {
      display: flex;
      > h5 {
        margin-right: rem(5);
      }
    }
  }

  .content {
    height: 100%;
    padding: rem(20) rem(402) rem(78);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    .logo-img {
      width: rem(320);
      background-image: url('/logo_images/logo_company_white.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 80%;
    }

    .panel {
      width: rem(540);
      background-color: #FFFFFF;
      box-shadow: 0 rem(2) rem(6) 0 rgba(0,0,0,0.30);
      padding: rem(40);

      .login-content {
        >.title {
          color: #202020;
        }
        .blocked-error {
          margin: rem(20) 0;
          padding: rem(10) rem(14);
          font-size: rem(12);
          color: #FF9900;
          background-color: #FFECCC;
          border: rem(1) solid #FF9900;
          > div {
            display: flex;
            align-items: center;
            color: #FF9900;
            margin-bottom: rem(10);
          }
          .icon-container {
            pointer-events: none;
            margin-right: rem(6);
          }
        }
        .panel-field {
          &.hint-message {
            margin-top: rem(30);
            background-color: #CCE0ff;
            border: rem(1) solid #0066FF;
            padding: rem(10) rem(14);
            color: #0066FF;
            display: flex;
            flex-direction: column;
            > .hint-title {
              height: rem(16);
              line-height: rem(16);
              margin-bottom: rem(10);
              color: #0066FF;
              display: flex;
              align-items: center;
              > .svg-icon {
                margin-right: rem(8);
              }
            }
          }
          &.remember-me {
            display: flex;
            margin-top: rem(30);
            > input[type='checkbox'] {
              margin-right: rem(13);
            }
          }
        }
      }

      .link {
        display: flex;
        justify-content: flex-end;
        padding: rem(10) rem(40);
        color: #6E6E6E;
        font-size: rem(12);
        > a {
          margin-left: rem(20);
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .tmis-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        .title {
          color: #0066FF;
          h6 {
            margin-top: rem(10);
            color: #6E6E6E;
          }
        }
        .panel-field {
          flex: 1;
          overflow: auto;
        }
      }
    }
  }

  .login-button {
    width: auto;
    min-width: rem(150);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 32px;

    > .icon {
      top: 5px;
      right: 6px;
      position: absolute;
    }
    &:hover {
      background-color: #2986FF;
      border: rem(1) solid #2986FF;
    }
    &:active {
      background-color: #004FD9;
      border: rem(1) solid #004FD9;
    }
  }
}
</style>

<style lang="less">

@login-text-color: #3E3E3E;
.login-page {
  .button-list {
    display: flex;
    justify-content: flex-end;
    button {
      margin: rem(25) 0;
      width: rem(150);
      &.apply-button {
        background-color: #0066FF;
        border: rem(1) solid #0066FF;
        &.disabled {
          background-color: #C2C2C2;
          border: rem(1) solid #C2C2C2;
          pointer-events: none;
        }
        &:hover {
          background-color: #2986FF;
          border: rem(1) solid #2986FF;
        }
        &:active {
          background-color: #004FD9;
          border: rem(1) solid #004FD9;
        }
      }
      &.cancel-button {
        color: #ADADAD;
        margin-left: rem(10);
        background-color: transparent;
        border: rem(1) solid #ADADAD;
        &:hover {
          color: #202020;
          border: rem(1) solid #202020;
        }
        &:active {
          color: #262626;
          border: rem(1) solid #262626;
        }
      }
    }
  }

  .panel-field {
    margin-top: rem(20);
    color: #3E3E3E;
    > h5 {
      line-height: rem(42);
    }

    &.password {
      position: relative;
      > .icon-container {
        position: absolute;
        top: rem(42);
        right: 0;
        background-color: transparent !important;
        color: #6E6E6E;
        &:hover, &:active {
          color: #202020;
        }
      }
    }
  }

  .input-field {
    &:hover:not(:active):not(:focus-within) .app-input-wrapper  {
      border: none;
      border-bottom: rem(1) solid #202020;
      color: @login-text-color;
    }
    &:active, &:focus-within {
      .app-input-wrapper {
        outline: none;
        border: none;
        border-bottom: rem(2) solid #0066FF;
        color: @login-text-color;
      }
    }

    .app-input-wrapper {
      padding: 0;
      background-color: #fff;
      border: none;
      border-bottom: rem(1) solid #ADADAD;
      &.error {
        border-bottom: rem(2) solid #FF9900;
      }
      .app-input[type] {
        background-color: transparent;
        color: @login-text-color;
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
          -webkit-text-fill-color: @login-text-color;
          box-shadow: 0 0 0px 1000px #fff inset;
          -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        }
      }
    }
    .app-input-error-message {
      color: #FF9900;
    }
  }

  .multiselect.app-dropdown {
    border: none;
    border-bottom: rem(1) solid #ADADAD;
    &:hover {
      border: none;
      border-bottom: rem(1) solid #202020;
      .multiselect__select:before {
        border-color: #202020 transparent transparent;
      }
    }
    .multiselect__select {
      width: rem(32);
      &:before {
        border-color: #6e6e6e transparent transparent;
        border-width: rem(8) rem(6) 0;
      }
    }
    .multiselect__tags {
      background-color: transparent;
    }
    .multiselect__content-wrapper {
      border: rem(1) solid #202020;
      background: #F9F9F9;
    }
    .multiselect__single {
      color: #3E3E3E;
    }
    li.multiselect__element:not(:first-child) {
      margin-top: rem(1);
    }

    .multiselect__option {
      height: rem(32);
      min-height: rem(32);
      background-color: #F9F9F9;
      color: #3E3E3E;
      &:hover, &.multiselect__option--selected {
        background-color: #0066FF;
        color: #FFFFFF;
      }
    }

  }

  .check-box {
    .label-box .text {
      color: @login-text-color;
    }
    .icon-container {
      background-color: transparent !important;
      &.status_checkbox_unchecked_normal {
        color: #ADADAD;
        &:hover, &:active {
          color: #202020;
        }
      }
      &.status_checkbox_checked_normal {
        color: #0066FF;
        &:hover, &:active {
          color: #004FD9;
        }
      }
    }
  }
  ::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
    border: solid rem(1) #FFFFFF;
    &:hover {
      border: solid rem(1) #FFFFFF;
    }
  }
}
</style>
